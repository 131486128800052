<template>
  <div>
    <b-card>
      <b-card-text>
        <label for="tags-pills">Tags</label>
        <b-form-tags
          v-model="tags"
          input-id="tags-pills"
          tag-variant="primary"
          tag-pills
          size="lg"
          separator=","
          placeholder=""
          class="mb-2"
        />

        <b-form-group
          label="Album name"
          label-for="album-name"
          class="mb-3"
        >
          <b-form-input
            id="album-name"
            placeholder=""
            size="lg"
            v-model="album"
          />
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <dashboard :uppy="uppy" :props="uppyConfig" />
        </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import { BCard, BCardText, BLink, BFormTags, BFormGroup, BFormInput, } from 'bootstrap-vue'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    Dashboard,
    BFormTags,
    BFormGroup,
    BFormInput,
  },

  data() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['image/jpeg', 'video/mp4'],
      },
      onBeforeUpload: files => {
       Object.keys(files).forEach(fileKey => {
          uppy.setFileMeta(files[fileKey].id, {
            tags: this.tags.join(','),
            album: this.album,
          })
        })
      },
    })
    const userData = JSON.parse(localStorage.getItem('userData'))

    uppy.use(XHRUpload, {
      endpoint: 'https://api.portstock.io/wp-json/products/upload',
      headers: {
        Authorization: 'Bearer ' + userData.token, 
      },
      timeout: 15 * 60 * 1000, // 15 minutes timeout
    })

    uppy.on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        tags: this.tags.join(','),
        album: this.album,
      })
    })

    return {
      album: '',
      tags: [],
      uppy,
      uppyConfig: {
        width: '100%',
        height: 400,
        inline: true,
        hideCancelButton: true,
        proudlyDisplayPoweredByUppy: false,
        theme: 'light',
      }
    }
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  }
}
</script>

<style>
.uppy-Root {
  font-family: inherit !important;
}

[dir] [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border: none;
}
</style>
